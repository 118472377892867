<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset">
                <b-overlay :show="loading">
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="reportMenuAssign.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="6" lg="6" md="6">
                       <ValidationProvider name="Module" vid="module_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="module_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro.module')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="reportMenuAssign.module_id"
                              :options="getModuleList(2)"
                              id="module_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Menu name (En)" vid="menu_name" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="menu"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('org_pro.menu_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="menu_name"
                            v-model="reportMenuAssign.menu_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Menu name (Bn)" vid="menu_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="email"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('org_pro.menu_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="name"
                            v-model="reportMenuAssign.menu_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Search Title (En)" vid="search_title_en" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="search_title_en"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.search_title_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            plain
                            v-model="reportMenuAssign.search_title_en"
                            id="search_title_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                            autocomplete="off"
                            >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Search Title (Bn)" vid="search_title_bn" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="search_title_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.search_title_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            plain
                            v-model="reportMenuAssign.search_title_bn"
                            id="search_title_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            autocomplete="off"
                            >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="6" lg="6" md="6" sm="6">
                      <ValidationProvider name="Grid Title (En)" vid="grid_title_en" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="grid_title_en"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.grid_title_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            plain
                            v-model="reportMenuAssign.grid_title_en"
                            id="grid_title_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                            autocomplete="off"
                            >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="6">
                      <ValidationProvider name="Grid Title (Bn)" vid="grid_title_bn" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="grid_title_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.grid_title_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            plain
                            v-model="reportMenuAssign.grid_title_bn"
                            id="grid_title_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            autocomplete="off"
                            >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="6">
                      <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="reportMenuAssign.service_id"
                            :options="serviceList"
                            id="service_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="6">
                      <ValidationProvider name="Report Process" vid="report_process_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="report_process_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.report_process') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="reportMenuAssign.report_process_id"
                            :options="reportProcessList"
                            id="report_process_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-overlay>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl, licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { menuStore, menuUpdate, reportMenuAssignStore, reportMenuAssignUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['item', 'id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      reportMenuAssign: {
        grid_title_en: '',
        grid_title_bn: '',
        search_title_en: '',
        search_title_bn: '',
        component_id: 2,
        module_id: 0,
        menu_name: '',
        menu_name_bn: '',
        service_id: 0,
        url: '',
        sorting_order: 100000000,
        associated_urls: '',
        org_id: 0,
        report_menu_details: [],
        menu_id: 0,
        report_process_id: ''
      },
      serviceList: [],
      reportProcessList: []
    }
  },
  created () {
    this.reportMenuAssign.org_id = this.userCheck()
    if (this.id) {
      this.reportMenuAssign = this.item
    }
  },
  mounted () {
    core.index()
  },
  watch: {
    'reportMenuAssign.org_id': function (newValue) {
      this.serviceList = this.getServiceList(newValue)
    },
    'reportMenuAssign.service_id': function (newValue) {
      this.reportProcessList = this.getReportProcessList(newValue)
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    getServiceList (orgId) {
      const serviceList = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.org_id === orgId)
      return serviceList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    async storeUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const tmpMenu = Object.assign(this.reportMenuAssign, { url: '/license-registration-service/license-step/reports/' + this.reportMenuAssign.menu_id })

      if (this.id) {
        const tmpUpdateMenu = Object.assign(this.reportMenuAssign, { url: '/license-registration-service/license-step/reports/' + this.reportMenuAssign.menu_id }, { component_id: 2 }, { sorting_order: 100000000 })
          result = await RestApi.putData(commonServiceBaseUrl, `${menuUpdate}/${this.item.menu_id}`, tmpUpdateMenu)
          if (result.success) {
            this.reportMenuAssign.menu_id = result.data.id
            const tmpMenuAssign = Object.assign(result.data, this.reportMenuAssign, { step_id: this.reportMenuAssign.step_id })
            result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${reportMenuAssignUpdate}/${this.item.id}`, tmpMenuAssign)
          }
      } else {
          const resultCommon = await RestApi.postData(commonServiceBaseUrl, menuStore, tmpMenu)
          if (resultCommon.success) {
            this.reportMenuAssign.menu_id = resultCommon.data.id
            result = await RestApi.postData(licenseRegistrationServiceBaseUrl, reportMenuAssignStore, this.reportMenuAssign)
            const saveMenu = Object.assign(resultCommon, this.reportMenuAssign, { url: '/license-registration-service/license-step/reports/' + resultCommon.data.id })
            // menu url update
            await RestApi.putData(commonServiceBaseUrl, `${menuUpdate}/${resultCommon.data.id}`, saveMenu)
          }
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.exist) {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
        return
      }

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getModuleList (componentId = 2) {
      const moduleList = this.$store.state.commonObj.moduleList.filter(item => item.status === 0)
      if (componentId) {
        const moduleData = moduleList.filter(module => module.component_id === componentId)
        const tmpList = moduleData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
        return tmpList
      }
    },
    getReportProcessList (serviceID) {
      return this.$store.state.licenseRegistration.commonObj.reportProcess.filter(item => item.service_id === serviceID)
    }
  }
}
</script>
